.app-a {
  transition: 0.25s;
  cursor: pointer;
  width: 215px;
  text-decoration: none;
  //height: 50px;
  color: $on-base;
  font-size: 20px;
  font-weight: 500;
  flex-shrink: 0;
  padding: 13px 21px;
  display: flex;
  column-gap: 15px;

  @include tablet {
    font-size: 18px;
    color: $base-light;
  }

  .arrow {
    margin-top: 5px;
  }

  &:hover {
    //color: $primary-dark;
  }

  &_active {
    //color: $primary-dark;
  }
}


.app-a__login {
  transition: 0.25s;
  cursor: pointer;
  //width: 215px;
  height: 100%;
  color: $on-primary;
  font-size: 15px;
  font-weight: 500;
  flex-shrink: 0;
  padding: 13px 15px;
  display: flex;
  column-gap: 15px;
  background-color: $btn-main-color;
  align-items: center;
  border-radius: 5px;
  border: 2px solid $btn-main-color-border;

  @include tablet {
    padding: 13px 10px;
  }
}
