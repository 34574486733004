/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import 'styles/main';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

.WhelpNoScrollAndHidden{
    #userwayAccessibilityIcon{
        display: none;
    }
}