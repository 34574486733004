$base: #fafafa;
$base-light-dark: #EAECEE;
$base-light: #ffffff;
$on-base: #000000;
$bg-color: #333;
$btn-bg-color: #F1F3F6;
$btn-color: #B1BCCD;
$btn-color-active: #292929;

$primary: #00bcd4;
$primary-dark: #008ba3;
$primary-light: #62efff;
$on-primary: #ffffff;

$secondary: #ffd54f;
$secondary-dark: #c8a415;
$secondary-light: #ffff81;
$on-secondary: #000000;

$error: #B00020;
$on-error: #ffffff;

$success: #99aa00;
$on-success: #ffffff;

///////////////////////////////
$btn-main-color:#fc9849;
$btn-main-color-border:#f6c5a0;
