@font-face {
  font-family: 'MarkPro'; /* Font family name for regular */
  src: url('~assets/fonts/MarkPro-Regular.otf') format('truetype');

}

@font-face {
  font-family: 'MarkPro'; /* Font family name for regular */
  src: url('~assets/fonts/MarkPro-Bold.otf') format('truetype');
}


@font-face {
  font-family: 'MarkPro'; /* Font family name for regular */
  src: url('~assets/fonts/MarkPro-Medium.otf') format('truetype');
}


$base-font-family : "MarkPro", sans-serif;
$base-font-size : 16px;
$base-line-height : 1.2;
