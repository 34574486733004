@import "colors";
@import "sizes";

@mixin shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

@mixin pagePadding{
  padding: 90px 25px 0 25px;
}

@mixin card {
  padding: 32px;
  background: $base;
  border-radius: 8px;
  @include shadow;
}

//-------------------- Responsive --------------------//

@mixin xx-large-desktop {
  @media (min-width: #{$xx-large-device-width}) {
    @content;
  }
}

@mixin lg-desktop {
  @media (min-width: #{$extra-large-device-width}) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: #{$large-device-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$medium-device-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$small-device-width}) {
    @content;
  }
}

@mixin extra-small-mobile {
  @media (max-width: #{$extra-small-device-width}) {
    @content;
  }
}

// useage is: @include mobile


//$grid-length:180px

@mixin grid($grid-length:235px , $grid-zIndex:-1){
  position: relative;
  &::before{
    width: 1100px;
    max-width: 100%;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    //left: 10px;
    left: 50%; /* Set the left to 50% */
    transform: translateX(-50%);
    right: 0;
    background-color: transparent;

    background-image: linear-gradient(to right, #FFEEC4 0%, #FFEEC4 1px, transparent 1px, transparent 170px);
    background-size: $grid-length 100%;
    padding: 0 207px;
    box-sizing: border-box;
    z-index: $grid-zIndex;

    @include mobile() {
      background-image: linear-gradient(to right, #FFEEC4 0%, #FFEEC4 1px, transparent 1px, transparent 70px);
      background-size: 90px 100%;
      padding: 0 63px;
    }
  }


}

