@import "reset";

@import "colors";
@import "sizes";
@import "mixins";
@import "extends";
@import "fonts";

@import "base";
@import "layout";
@import "vendor";

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

@import "pagination";

html,
body {
  position: relative;
  min-height: 100%;
  overflow-y: auto !important;
  margin: 0;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  color: $on-base;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}



body {
  //position: relative;
  background-color: #FFFDF7 !important;
  min-height: 100vh;
  //@include grid()
}

* {
  box-sizing: border-box !important;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

textarea{
  min-height: 55px;
}

a {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body .uwy.userway_p4 .userway_buttons_wrapper {
  left: 4vw !important;

  @include tablet {
    left: 11vw !important;
  }
}

body .uwy .uai {
  @include tablet {
    width: 64px !important;
    height: 64px !important;
  }
}
