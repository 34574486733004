ngb-pagination::ng-deep ul{
  gap: 10px;

  li {
    display: grid;
    place-items: center;
    margin: 0 -2px;
    border: none;
    border-radius: 100%;
    overflow: hidden;
    width: 45px;
    height: 45px;
    a{
      width: 100%;
      height: 100%;
      display: grid;
      place-items: center;
      border-radius: 100%;
      color: #000;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 27px; /* 168.75% */
      letter-spacing: -0.078px;

    }

    .page-link:focus{
      border: 0;
      color: #000;
    }




  }

  li:first-child{
    a {
      span {
        display: none !important;
      }

      &::before {
        content: '<' !important;
      }
    }
  }

  li:last-child{
    a {
      span {
        display: none !important;
      }

      &::before {
        content: '>' !important;
      }
    }
  }

  li:hover{
    a{
      color: #FC9849;
    }
  }

  .active{
    border: 2px solid #FC9849;


    .page-link{
      background-color: white;
      color: #000 ;
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }




  }

  .disabled{
    .page-link{
      background-color: white;
      color: #000 ;
      border: 0;
    }

  }
}

.page-item:last-child .page-link, .page-item:first-child .page-link {
  border-top-right-radius: 100% !important;
  border-bottom-right-radius: 100% !important;
  border-top-left-radius: 100% !important;
  border-bottom-left-radius: 100% !important;
}
